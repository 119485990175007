<template>
  <div>
    <div class="bgBox">
      <el-carousel arrow="never" indicator-position="none">
        <el-carousel-item v-for="img in bglist" :key="img.id">
          <img class="img" :src="img.url" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="bgTitle">
        <div>资产全生命周期解决方案</div>
        <div>软硬件一体化综合解决方案高效、专业、轻松的管理方式</div>
      </div>
    </div>
    <div class="mainBox">
      <div class="mainauto">
        <div class="cardBox" id="fwsy">
          <img src="@/assets/solution/001.png" alt="" />

          <div class="cardTitle">RFID防伪溯源方案</div>
          <div class="cardMain">
            <span>非接触式无线射频，避免伪劣更轻松</span
            ><router-link to="/fwsysolution">了解更多>></router-link>
          </div>
        </div>
        <div class="cardBox" id="zhubao">
          <img src="@/assets/solution/002.png" alt="" />

          <div class="cardTitle">RFID珠宝管理应用于零售行业解决方案</div>
          <div class="cardMain">
            <span>告别繁琐，从RFID技术开始</span
            ><router-link to="/jewelrymanage">了解更多>></router-link>
          </div>
        </div>
        <div class="cardBox" id="dag">
          <img src="@/assets/solution/003.png" alt="" />

          <div class="cardTitle">RFID在档案馆信息管理建设中的解决方案</div>
          <div class="cardMain">
            <span>实现库房信息自动化，提高流动效率</span
            ><router-link to="/filemanage">了解更多>></router-link>
          </div>
        </div>
        <div class="cardBox" id="yancao">
          <img src="@/assets/solution/004.png" alt="" />

          <div class="cardTitle">RFID烟草仓储管理系统方案</div>
          <div class="cardMain">
            <span>提升运营效率，信息透明化，管理更简单</span
            ><router-link to="/smokesolution">了解更多>></router-link>
          </div>
        </div>
        <div class="cardBox">
          <img src="@/assets/solution/005.png" alt="" />

          <div class="cardTitle">RFID数据中心资产管理解决方案</div>
          <div class="cardMain">
            <span>应用RFID技术，降低安全隐患</span
            ><router-link to="/datasolution">了解更多>></router-link>
          </div>
        </div>
        <div class="cardBox" id="fuzhuang">
          <img src="@/assets/solution/006.png" alt="" />

          <div class="cardTitle">RFID服装行业解决方案</div>
          <div class="cardMain">
            <span>射频技术实现自动盘点、快速结算</span
            ><router-link to="/clothingsolution">了解更多>></router-link>
          </div>
        </div>
        <div class="cardBox" id="yiliao">
          <img src="@/assets/solution/007.png" alt="" />

          <div class="cardTitle">RFID在医疗中的应用方案</div>
          <div class="cardMain">
            <span>RFID射频可实时追踪人员信息</span
            ><router-link to="/medicinesolution">了解更多>></router-link>
          </div>
        </div>
        <div class="cardBox" id="jzx">
          <img src="@/assets/solution/008.png" alt="" />

          <div class="cardTitle">RFID集装箱管理解决方案</div>
          <div class="cardMain">
            <span>追查源头，堆场集装箱自动管理</span
            ><router-link to="/containersolution">了解更多>></router-link>
          </div>
        </div>
        <div class="cardBox">
          <img src="@/assets/solution/009.png" alt="" />

          <div class="cardTitle">RFID高档酒类防伪解决方案</div>
          <div class="cardMain">
            <span>有效保证厂家、商家利益</span
            ><router-link to="/alcoholsolution">了解更多>></router-link>
          </div>
        </div>
        <div class="cardBox" id="qiangzhi">
          <img src="@/assets/solution/010.png" alt="" />

          <div class="cardTitle">RFID枪支管理系统方案</div>
          <div class="cardMain">
            <span>对人员、车辆、军械、物资军事化管理</span
            ><router-link to="/gunssolution">了解更多>></router-link>
          </div>
        </div>
        <div class="cardBox">
          <img src="@/assets/solution/011.png" alt="" />

          <div class="cardTitle">RFID建筑行业构建管理方案</div>
          <div class="cardMain">
            <span>结合RFID技术对其定位、追溯、监控等</span
            ><router-link to="/buildsolution">了解更多>></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "asset-program",

  data() {
    return {
      title: "",
      bglist: [{ id: 0, url: require("@/assets/solution/bg.png") }],
    };
  },
  created() {

  },
  methods: {
  }
};
</script>

<style scoped>
.bgBox {
  width: 100%;
  height: 700px;
  position: relative;
}
.bgBox >>> .el-carousel__container {
  height: 700px;
}
.bgTitle {
  min-width: 700px;
  position: absolute;
  color: #fff;
  left: 50%;
  top: 50%;
  padding: 30px 0;
  transform: translate(-50%, -50%);
  z-index: 9;
  line-height: 50px;
  background: url("../assets/solution/title_bg.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.bgTitle > div:nth-child(1) {
  font-size: 48px;
}
.bgTitle > div:nth-child(2) {
  font-size: 14px;
}
.img {
  width: 100%;
  height: 100%;
}
.mainBox {
  width: 100%;
  background-color: #f7f9fc;
  padding-bottom: 194px;
  background: url("../assets/index/bottom_bg.png") no-repeat bottom;
  background-size: 100% 10%;
}
.mainauto {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}
.cardBox {
  width: 368px;
  height: 322px;
  margin-top: 56px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  line-height: 30px;
  margin-right: 48px;
}
.cardBox:nth-child(3n) {
  margin-right: 0;
}
.cardBox img {
  width: 100%;
  height: 238px;
}

.cardTitle {
  color: #333;
  font-size: 18px;
  padding-left: 10px;
  font-weight: bold;
}
.cardMain {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
  padding: 0 10px;
}
.cardMain a {
  color: #5590ec;
}
.fadeIn {
  animation: fadeIn 1s ease 0s both;
}
@keyframes fadeIn {
  0% {
    margin-top:56px;
  }
 25% {
    margin-top: 7px;
  }
  50% {
    margin-top: 56px;
  }
  75% {
    margin-top: 7px;
  }
  100%{
     margin-top: 56px;
  }
}
</style>